import { Routes, Route, Navigate } from "react-router-dom";

// Basic Styling
import "./css/styles.css";
import "./css/login.css";

//CSS Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

//HOC
import ProtectedRoute from "./Routes/ProtectedRoute";
import GuestRoute from "./Routes/GuestRoute";
import NewUserRoute from "./Routes/NewUserRoute";
import { AuthProvider } from "./Context/AuthContext";

// Pages
import LoginPage from "./Pages/Login Page/LoginPage";
import RegisterPage from "./Pages/Register Page/RegisterPage";
import UnderConstruction from "./Pages/UnderConstruction/UnderConstruction";
import ForgotPassword from "./Pages/Forgot Password/ForgotPassword";
import ResetPassword from "./Pages/Forgot Password/ResetPassword";
import VerifyPassword from "./Pages/Forgot Password/VerifyPassword";
import ProductRegister from "./Pages/Product Registration/ProductRegister";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MyProduct from "./Pages/Product/MyProduct";
import Registration_Successful from "./Pages/Forgot Password/Registration_Successful";
import Verification_Successful from "./Pages/Forgot Password/Verification_Successful";
import Verify_Founder from "./Pages/Forgot Password/Verify_Founder";
import PasswordResetConfirmation from "./Pages/Forgot Password/PasswordResetConfirmation";

// API CONFIG
import { apiURI } from "./config";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import { ToastContainer } from "react-toastify";

import { BinanceTestnet } from "@thirdweb-dev/chains";
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  embeddedWallet,
} from "@thirdweb-dev/react";

function App() {
  // API CONFIGURATIONS
  const link = createUploadLink({
    uri: apiURI.URL,
  });

  const cache = new InMemoryCache();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-power": process.env.REACT_APP_POWER_KEY,
        "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
        "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
        "x-range-name": process.env.REACT_APP_RANGE_NAME,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache,
    resolvers: {},
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={client}>
      <ThirdwebProvider
        activeChain={BinanceTestnet}
        supportedChains={[BinanceTestnet]}
        clientId="322ef3b7bb795b36b25427bb8e6ff22e"
        supportedWallets={[
          metamaskWallet({ recommended: true }),
          coinbaseWallet(),
          walletConnect(),
          localWallet(),
          embeddedWallet(),
        ]}
        dAppMeta={{
          name: "Watcher Finance",
          description: "Product Validation Platform",
          logoUrl:
            "https://dev.sentinel.watcher.finance/static/media/sentinel-logo.b7b19edd278111be4ee7.png",
          url: "https://dev.sentinel.watcher.finance",
          isDarkMode: false,
        }}
      >
        <AuthProvider>
          <div className="App">
            <Routes>
              <Route
                path="/login"
                element={<GuestRoute element={<LoginPage />} />}
              />
              <Route
                path="/register"
                element={<GuestRoute element={<RegisterPage />} />}
              />
              <Route
                path="/forgot-password"
                element={<GuestRoute element={<ForgotPassword />} />}
              />
              <Route
                path="/reset-password"
                element={<GuestRoute element={<ResetPassword />} />}
              />
              <Route
                path="/verify-password"
                element={<GuestRoute element={<VerifyPassword />} />}
              />
              <Route
                path="/verify/:id"
                element={<GuestRoute element={<Verify_Founder />} />}
              />
              <Route
                path="/registration-successful"
                element={<GuestRoute element={<Registration_Successful />} />}
              />
              <Route
                path="/password-reset"
                element={<GuestRoute element={<PasswordResetConfirmation />} />}
              />
              <Route
                path="/verification-successful"
                element={<GuestRoute element={<Verification_Successful />} />}
              />

              <Route
                path="/product-register"
                element={<NewUserRoute element={<ProductRegister />} />}
              />
              <Route
                path="/dashboard"
                element={<ProtectedRoute element={<Dashboard />} />}
              />
              <Route
                path="/product"
                element={<ProtectedRoute element={<MyProduct />} />}
              />
              <Route
                path="/under-construction"
                element={<ProtectedRoute element={<UnderConstruction />} />}
              />

              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </AuthProvider>
      </ThirdwebProvider>
    </ApolloProvider>
  );
}

export default App;
