import React from "react";

import "../css/dashboard.css";

import {
  IconBrandTwitter,
  IconBrandLinkedin,
  IconBrandTelegram,
} from "@tabler/icons-react";
const Card = ({ product }) => {
  return (
    <div className="product-card">
      <div className="card-content">
        <div className="d-flex justify-content-between mb-3">
          <h3 className="product-header-text">{product.product_name}</h3>
          <div className="card-logo">
            <img
              src={product.product_logo}
              alt={`${product.product_logo} logo`}
            />
          </div>
        </div>
        <p className="one-line-desc">
          {product.one_line_description
            ? product.one_line_description.slice(0, 55)
            : product.one_line_description}
        </p>
        <hr />
        <p className="card-items">
          <span>Product Type :</span>
          <span>{product.product_type}</span>
        </p>
        <p className="card-items">
          <span>Category :</span>
          {product.category && product.category.length > 0 ? (
            product.category.map((category, index) => {
              return (
                <span className="ans ">{category.value.substring(0, 15)}</span>
              );
            })
          ) : (
            <span className="ans ">Not Available</span>
          )}
        </p>
      </div>

      <div className="card-social">
        <a
          href={product.twitter_link}
          target="_blank"
          rel="noreferrer"
          style={{ cursor: "pointer" }}
        >
          <IconBrandTwitter />
        </a>
        <a
          href={product.linkedin_link}
          target="_blank"
          rel="noreferrer"
          style={{ cursor: "pointer" }}
        >
          <IconBrandLinkedin />
        </a>
        <a
          href={product.telegram_link}
          target="_blank"
          rel="noreferrer"
          style={{ cursor: "pointer" }}
        >
          <IconBrandTelegram />
        </a>
        <a
          href={`https://dashboard.sentinel.watcher.finance/review2earn/products/${product._id}`}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary "
          style={{ float: "right", color: "white" }}
        >
          Validate
        </a>
      </div>
    </div>
  );
};

export default Card;
